import React, { useState } from "react";
import image from '../../assets/3b1f25_d6aeb137f2ce4a4fb5af06fbba6a47b5.jpg';

function BannerTop() {
    const [showNotice, setShowNotice] = useState(false);

    return (
        <>
            <div className="banner-top-container" id="top">
                {showNotice && (
                    <div className="bg-white  text-center" style={{ padding: '10px', marginTop: '55px', position: 'relative' }}>
                        <p>AVISO DE CARNAVAL! Dia 28 de fevereiro, atendimento das 8h às 13h. Retornaremos dia 06/03 em horário normal.</p>
                        <button onClick={() => setShowNotice(false)} style={{ position: 'absolute', right: '10px', top: '10px' }}>X</button>
                    </div>
                )}
                <img src={image} alt="Banner Top" className="banner-top-image" />
                <div className="banner-top-content">
                    <p className="mb-0" style={{ color: '#940110' }}>CAORT</p>
                    <h1 className="text-dark"><b>ORTOPEDIA E TRAUMATOLOGIA</b></h1>
                </div>
            </div>
        </>
    );
}

export default BannerTop;
